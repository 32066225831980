import { render, staticRenderFns } from "./SummaryStudentInfoShare.vue?vue&type=template&id=0d0f20ac&scoped=true"
import script from "./SummaryStudentInfoShare.vue?vue&type=script&lang=js"
export * from "./SummaryStudentInfoShare.vue?vue&type=script&lang=js"
import style0 from "./SummaryStudentInfoShare.vue?vue&type=style&index=0&id=0d0f20ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d0f20ac",
  null
  
)

export default component.exports